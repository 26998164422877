import { Table, Button, Label, Details, Checkboxes, ErrorSummary, Textarea } from "nhsuk-react-components";
import React, { useState } from "react";
import GoBack from "../../../../Components/Shared/GoBack";
import { useNavigate } from "react-router-dom";
import { dsaContent, dsaErrorMessage, fileTypeSize, legalBasisValue, maxLength, participantErrorMessage, routeName, taskListSectionStatus } from "../../../../config/page.config";
import { getCookie, submitSpinner, fetchInterceptor } from '../../../../Helper/Utility';
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from '../../../../state/app/store';
import { IApplication, IParticipant } from "../../../../state/models/app.interface";
import { getParticipantDetails, setParticipantDetailsData } from "../../../../state/slice/Participant/Participant.slice";
import ControllerHeader from '../../../../Components/Shared/ControllerHeader'
import { getDSADetails, setDSAApplicationDetailsData } from "../../../../state/slice/DSA/DSA.slice";
import { SubmitScreenCohortData_URL } from "../../../../config/api-endpoints.config";

/*Add the data items that will link the cohort supplied with NHS data*/
const LinkParticipantData: React.FC = () => {
    let navigate = useNavigate();
    let authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dispatch: AppDispatch = useAppDispatch();
    const initialState: IParticipant = useAppSelector(getParticipantDetails) || {};
    const [participantDetails, setParticipantDetails] = useState(initialState)
    const isFromAnswerPage: string = participantDetails?.participantcheckYourAnswerPage!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const [dsaApplicationData, setDsaApplicationData] = useState(dsaApplicationDetails || {});
    const saveApplicationDataInStore = (stateData?: IApplication) => {
        dispatch(setDSAApplicationDetailsData({ ...dsaApplicationData, ...stateData }));
    }
    const saveDataInStore = (stateData?: IParticipant) => {
        dispatch(setParticipantDetailsData({ ...participantDetails, ...stateData }));
    }
    let consentLegalBasis = participantDetails?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.consentValue)[0];
    let nhsLegalBasis = participantDetails?.dataSetLegalBasisList?.filter(x => x.value === legalBasisValue.nhsHealthValue)[0];
    const [summaryLinkErrorMessage, setSummaryLinkErrorMessage] = useState("");
    const [linkingParticipantErrorMessage, setLinkingParticipantErrorMessage] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedState: IParticipant = {};
        updatedState = { ...participantDetails, [e.target.name]: e.target.checked ? true : false }
        if (!([dsaContent.dateOFDeath!, dsaContent.addressDate!, dsaContent.telephoneNumber!].includes(e.target.name))) {
            resetError(e);
        }
        setParticipantDetails(updatedState);
        saveDataInStore(updatedState);
    }

    /* To reset the error */
    const resetError = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setSummaryLinkErrorMessage("");
            setLinkingParticipantErrorMessage("");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const IsValid: boolean = true;//ValidateFields();
        if (IsValid) {
            submitSpinner(e, submitLinkingParticipant);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const submitLinkingParticipant = () => {
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : "",
            "StudyId": true,
            "DateOfDeath": participantDetails.dateOfDeath,
            "NhsNumber": participantDetails.nhsNumber,
            "DateOfBirth": participantDetails.dateOfBirth,
            "AddressDate": participantDetails.addressDate,
            "Forename": participantDetails.forename,
            "Surname": participantDetails.surname,
            "Sex": participantDetails.sex,
            "TelephoneNumber": participantDetails.telephoneNumber,
            "Postcode": participantDetails.postcode,
            "AdditionalLinkageCohortInformation": participantDetails.additionalLinkageCohortInformation,
            "SectionStatus": dsaApplicationData.aboutParticipantSectionStatus
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(SubmitScreenCohortData_URL, requestOptions)
            .then(() => {
                const participantDataList = participantDetails?.participantContributorList;
                let sectionStatus: IApplication = {
                    ...dsaApplicationData, aboutParticipantSectionStatus: taskListSectionStatus.inProgress
                }
                setDsaApplicationData(sectionStatus);
                saveApplicationDataInStore(sectionStatus);
                if (isFromAnswerPage !== undefined && isFromAnswerPage === dsaContent.checkYourAnswersPageText && participantDetails.cohort)
                    navigate(routeName.participantSummary);
                else if (participantDataList && participantDataList.length > 0) {
                    navigate(routeName.addCohortContributor);
                }
                else {
                    navigate(routeName.whoWillProvide);
                }

            })
            .catch(() => navigate(routeName.serviceError));
    }

    /* To show the error summary */
    const errorSummary: JSX.Element = (
        <>
            <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                <ErrorSummary.Title id="error-summary-title">{dsaErrorMessage.selectSummary_ErrorMessage}</ErrorSummary.Title>
                <ErrorSummary.Body>
                    <ErrorSummary.List><ErrorSummary.Item href="#linkingParticipant">{summaryLinkErrorMessage}
                    </ErrorSummary.Item>
                    </ErrorSummary.List></ErrorSummary.Body> </ErrorSummary>
        </>
    )

    /* To Validate the each field's data */
    const ValidateFields = () => {
        let isValid: boolean = true;
        const requiredFields = participantDetails.nhsNumber && participantDetails.dateOfBirth;
        const recommendedFileds = (participantDetails.nhsNumber && (participantDetails.forename || participantDetails.sex || participantDetails.surname || participantDetails.postcode)) ||
            (participantDetails.dateOfBirth && (participantDetails.forename || participantDetails.sex || participantDetails.surname || participantDetails.postcode))

        if (((participantDetails.nhsNumber && !participantDetails.dateOfBirth) || (!participantDetails.nhsNumber && participantDetails.dateOfBirth)) && !recommendedFileds) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.linkingParticipant_ErrorMessage}`);
            setLinkingParticipantErrorMessage(`${participantErrorMessage.linkingParticipant_ErrorMessage}`);
            isValid = false;
        }
        else if (!requiredFields && !recommendedFileds) {
            setSummaryLinkErrorMessage(`${participantErrorMessage.linkingParticipantrequired_ErrorMessage}`);
            setLinkingParticipantErrorMessage(`${participantErrorMessage.linkingParticipantrequired_ErrorMessage}`);
            isValid = false;
        }
        return isValid;
    }

    const handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let updateState: IParticipant = {}
        updateState = {
            ...participantDetails,
            additionalLinkageCohortInformation: e.target.value
        }
        setParticipantDetails(updateState);
        saveDataInStore(updateState);
    }


    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <>
                            <div className="nhsuk-grid-column-two-thirds">
                                <GoBack condition={participantDetails.participantcheckYourAnswerPage === dsaContent.checkYourAnswersPageText ? routeName.participantSummary : (consentLegalBasis?.checked || nhsLegalBasis?.checked) ? dsaContent.addAnotherDatasetRecord : dsaContent.legalbasis}></GoBack>
                                <h1><span className="nhsuk-caption-l nhsuk-caption--bottom ">
                                    About the participants</span>
                                    Confidential data you are allowed to share with NHS England
                                </h1>
                                {summaryLinkErrorMessage !== "" ? errorSummary : null}
                                <p>Based on how you meet or set aside your duty of confidentiality, which data items are you permitted to send to NHS England?
                                The date of birth must be included to allow for the NHS Master Person Service (MPS) to match the data.</p>
                              <p>If you are not permitted to send date of birth, email <a href={'mailto:data.applications@nhsdigital.nhs.uk?subject=' + dsaApplicationData?.referenceNumber}>data.applications@nhsdigital.nhs.uk</a></p>
                                <div className="nhsuk-hint">
                                    Select all options that are relevant to you.
                                </div>
                            </div>
                            <div className="nhsuk-grid-column-two-thirds">
                                <Checkboxes id='linkingParticipant' name='linkingParticipant' error={linkingParticipantErrorMessage}>
                                    <div className="nhsuk-u-padding-top-3"></div>
                                    <div className="nhsuk-element-align_left">
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.addressDate} checked={participantDetails.addressDate ? true : false} onChange={handleChange}>
                                            <Label>Address date</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.dateOfBirth} checked={participantDetails.dateOfBirth ? true : false} onChange={handleChange} >
                                            <Label>Date of Birth</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.dateOFDeath} checked={participantDetails.dateOfDeath ? true : false} onChange={handleChange}>
                                            <Label>Date of death</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.forename} checked={participantDetails.forename ? true : false} onChange={handleChange}>
                                            <Label>Family name</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.sex} checked={participantDetails.sex ? true : false} onChange={handleChange}>
                                            <Label>Gender</Label>
                                        </Checkboxes.Box>
                                    </div>
                                    <div className="nhsuk-element-align_right">
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.surname} checked={participantDetails.surname ? true : false} onChange={handleChange}>
                                            <Label>Given name</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.nhsNumber} checked={participantDetails.nhsNumber ? true : false} onChange={handleChange}>
                                            <Label>NHS number</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.postCode} checked={participantDetails.postcode ? true : false} onChange={handleChange}>
                                            <Label>Postcode</Label>
                                        </Checkboxes.Box>
                                        <div className="nhsuk-u-padding-bottom-5"></div>
                                        <Checkboxes.Box value={dsaContent.yes} name={dsaContent.telephoneNumber} checked={participantDetails.telephoneNumber ? true : false} onChange={handleChange}>
                                            <Label>Telephone number</Label>
                                        </Checkboxes.Box>
                                    </div>
                                </Checkboxes>
                            </div>
                            <div className="nhsuk-grid-column-two-thirds">
                                <div className="nhsuk-u-padding-bottom-7"></div>
                                <Button name="Continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>
                            </div>
                        </>
                    </div>
                </main>
            </div>
        </>)
}

export default LinkParticipantData;